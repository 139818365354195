import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Row, Col, Form } from "react-bootstrap"
import graph1 from "../../images/topic-2/section-2/graph-1.svg"
import fish from "../../images/topic-2/section-2/fish-cells-1.png"
import equation1 from "../../images/equations/logistic-equation-faded.svg"
import ButtonLink from "../../components/buttonLink"
import BottomNav from "../../components/bottomNav"
import Instructions from "../../components/instructions"
import TopHeader from "../../components/topHeader"
import MyBreadcrumb from "../../components/myBreadcrumb"
import RespMsg from "../../components/respMsg"
import RadioQuestion from "../../components/radioQuestion"
import parse from "html-react-parser"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
// =========================================================================================================
const LogGrowthSection2 = ({ location }) => {
  const { t } = useTranslation()

  const pageContent = {
    breadcrumb: parse(t("Topic2_Section2_breadcrumb[1]")),
    title: parse(t("Topic2_Section2_title")),
    topTexts: [
      parse(t("Topic2_Section2_topText[0]")),
      parse(t("Topic2_Section2_topText[1]")),
      parse(t("Topic2_Section2_topText[2]")),
      parse(t("Topic2_Section2_topText[3]")),
      parse(t("Topic2_Section2_topText[4]")),
    ],
    bottomTexts: [
      parse(t("Topic2_Section2_bottomText[0]")),
      parse(t("Topic2_Section2_bottomText[1]")),
      parse(t("Topic2_Section2_bottomText[2]")),
      parse(t("Topic2_Section2_bottomText[3]")),
      parse(t("Topic2_Section2_bottomText[4]")),
      parse(t("Topic2_Section2_bottomText[5]")),
    ],
    images: [
      {
        src: equation1,
        alt: parse(t("Topic2_Section2_images[0].alt")),
        text: parse(t("Topic2_Section2_images[0].caption")),
      },
      {
        src: graph1,
        alt: parse(t("Topic2_Section2_images[1].alt")),
        text: parse(t("Topic2_Section2_images[1].caption")),
      },
      {
        src: fish,
        alt: parse(t("Topic2_Section2_images[2].alt")),
        text: parse(t("Topic2_Section2_images[2].caption")),
      },
      {
        src: graph1,
        alt: parse(t("Topic2_Section2_images[3].alt")),
        text: parse(t("Topic2_Section2_images[3].caption")),
      },
      {
        src: graph1,
        alt: parse(t("Topic2_Section2_images[4].alt")),
        text: parse(t("Topic2_Section2_images[4].caption")),
      },
    ],
    topicsTitle: "",
    instructions: [
      parse(t("Topic2_Section2_instructions[0]")),
      parse(t("Topic2_Section2_instructions[1]")),
      parse(t("Topic2_Section2_instructions[2]")),
      parse(t("Topic2_Section2_instructions[3]")),
    ],
    radios: [
      {
        label: parse(t("Topic2_Section2_radios[0].label")),
        answer: false,
        msg: parse(t("Topic2_Section2_radios[0].msg")),
      },
      {
        label: parse(t("Topic2_Section2_radios[1].label")),
        answer: false,
        msg: parse(t("Topic2_Section2_radios[1].msg")),
      },
      {
        label: parse(t("Topic2_Section2_radios[2].label")),
        answer: false,
        msg: parse(t("Topic2_Section2_radios[2].msg")),
      },
      {
        label: parse(t("Topic2_Section2_radios[3].label")),
        answer: false,
        msg: parse(t("Topic2_Section2_radios[3].msg")),
      },
      {
        label: parse(t("Topic2_Section2_radios[4].label")),
        answer: true,
        msg: parse(t("Topic2_Section2_radios[4].msg")),
      },
    ],
    ccStage: {
      answer: 50,
      errorMsg: parse(t("Topic2_Section2_ccStage.errorMsg")),
      successMsg: parse(t("Topic2_Section2_ccStage.successMsg")),
      finalErrorMsg: parse(t("Topic2_Section2_ccStage.finalErrorMsg")),
    },
  }
  //===============================================================================
  const [msgType, setMsgType] = useState("")
  const [msgContent, setMsgContent] = useState("")
  const [showMsg, setShowMsg] = useState(false)
  const [disableChoices, setDisableChoices] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(true)
  const [stage, setStage] = useState(0)
  const [ccAnswer, setCCAnswer] = useState(0)
  const [errorCount, setErrorCount] = useState(0)
  const [showRadioCheck, setShowRadioCheck] = useState(true)
  const [resetPlaceholder, setResetPlaceholder] = useState(false)

  //===============================================================================
  return (
    <Layout>
      <SEO title={parse(t("Topic2_Section2_pageTitle"))} />
      <MyBreadcrumb
        topicLink="logisticgrowth"
        topicTitle={parse(t("Topic2_Section2_breadcrumb[0]"))}
        currentTitle={pageContent.breadcrumb}
      />
      <div className="homeContent">
        {/* stage 0 */}
        <div hidden={stage != 0}>
          <TopHeader title={pageContent.title} />
          <Row>
            <Col sm="6">
              <p>{pageContent.topTexts[stage]}</p>
            </Col>
            <Col sm="6">
              <center>
                <img
                  src={pageContent.images[0].src}
                  alt={pageContent.images[0].alt}
                  className="roundMe"
                />
                <p className="smText alignLeft">{pageContent.images[0].text}</p>

                <img
                  src={pageContent.images[1].src}
                  alt={pageContent.images[1].alt}
                  className="roundMe"
                />
                <p className="smText alignLeft">{pageContent.images[1].text}</p>
              </center>
            </Col>
          </Row>
        </div>

        {/* STAGE 1 */}
        <div hidden={stage != 1}>
          <TopHeader
            title={pageContent.title}
            content={pageContent.topTexts[stage]}
          />
          <Instructions content={pageContent.instructions[stage]} />
          <p>{pageContent.bottomTexts[stage]}</p>
          <Form>
            <RadioQuestion
              data={[
                {
                  id: "radio1",
                  name: "formRadios",
                  label: pageContent.radios[0].label,
                  answer: pageContent.radios[0].answer,
                  checked: showRadioCheck == false ? 0 : null,
                },
                {
                  id: "radio2",
                  name: "formRadios",
                  label: pageContent.radios[1].label,
                  answer: pageContent.radios[1].answer,
                  checked: showRadioCheck == false ? 0 : null,
                },
                {
                  id: "radio3",
                  name: "formRadios",
                  label: pageContent.radios[2].label,
                  answer: pageContent.radios[2].answer,
                  checked: showRadioCheck == false ? 0 : null,
                },
                {
                  id: "radio4",
                  name: "formRadios",
                  label: pageContent.radios[3].label,
                  answer: pageContent.radios[3].answer,
                  checked: showRadioCheck == false ? 0 : null,
                },
                {
                  id: "radio5",
                  name: "formRadios",
                  label: pageContent.radios[4].label,
                  answer: pageContent.radios[4].answer,
                  checked: showRadioCheck == false ? 0 : null,
                },
              ]}
              // disabled={disableChoices == true}
              myOnClick={(answer, label, id) => {
                switch (id) {
                  case "radio1":
                    setMsgContent(pageContent.radios[0].msg)
                    break
                  case "radio2":
                    setMsgContent(pageContent.radios[1].msg)
                    break
                  case "radio3":
                    setMsgContent(pageContent.radios[2].msg)
                    break
                  case "radio4":
                    setMsgContent(pageContent.radios[3].msg)
                    break
                  case "radio5":
                    setMsgContent(pageContent.radios[4].msg)
                    break
                  default:
                    return null
                }
                if (answer == true) {
                  setMsgType("correct")
                } else {
                  setMsgType("error")
                }
                setShowMsg(true)
                setDisableChoices(answer)
                setNextBtnEnabled(answer)
              }}
              disabled={disableChoices}
            />
          </Form>
          <RespMsg type={msgType} content={msgContent} hidden={!showMsg} />
        </div>
        {/* STAGE 2 */}
        <div hidden={stage != 2}>
          <TopHeader
            title={pageContent.title}
            content={pageContent.topTexts[stage]}
          />
          <Row>
            <Col sm="6">
              <center>
                <img
                  src={pageContent.images[2].src}
                  alt={pageContent.images[2].alt}
                  style={{ maxHeight: "300px" }}
                />
                <p className="smText alignLeft">{pageContent.images[2].text}</p>
              </center>
            </Col>
            <Col sm="6">
              <center>
                <img
                  src={pageContent.images[3].src}
                  alt={pageContent.images[3].alt}
                  style={{ maxHeight: "300px" }}
                />
                <p className="smText alignLeft">{pageContent.images[3].text}</p>
              </center>
            </Col>
          </Row>
          <Instructions content={pageContent.instructions[stage]} />
          <p>{pageContent.bottomTexts[stage]}</p>

          <Row style={{ width: "50%" }}>
            <Col sm="4">
              {" "}
              <Form.Control
                placeholder="K"
                disabled={errorCount >= 4}
                onChange={e => setCCAnswer(e.target.value)}
                type="number"
                // minlength="9"
                // maxlength="11"
                value={resetPlaceholder == true ? "" : null}
              />
            </Col>
            <Col sm="8">
              {" "}
              <ButtonLink
                // btnLink="#top"
                btnWidth
                variant="info"
                btnText={parse(t("Submit"))}
                className="btnNext"
                disabled={(errorCount >= 4) | nextBtnEnabled}
                onClick={() => {
                  if (ccAnswer != pageContent.ccStage.answer) {
                    setMsgContent(pageContent.ccStage.errorMsg)
                    setMsgType("error")
                  } else {
                    setMsgContent(pageContent.ccStage.successMsg)
                    setMsgType("correct")
                    setNextBtnEnabled(true)
                  }
                  if (
                    errorCount >= 3 &&
                    ccAnswer != pageContent.ccStage.answer
                  ) {
                    setMsgContent(pageContent.ccStage.finalErrorMsg)
                    setMsgType("error")
                    setNextBtnEnabled(true)
                  }
                  setShowMsg(true)
                  setErrorCount(errorCount + 1)
                }}
                // btnColor="#4A331C"
              />
            </Col>
          </Row>

          <br />
          <RespMsg type={msgType} content={msgContent} hidden={!showMsg} />
        </div>

        <hr />
        <Row>
          <Col sm="12" md="12" className="alignRight">
            <BottomNav
              onClickPrev={() => {
                setMsgType("")
                setMsgContent("")
                setShowMsg(false)
                setDisableChoices(false)
                setErrorCount(0)
                switch (stage) {
                  case 1:
                    setStage(stage - 1)
                    setNextBtnEnabled(true)
                    setShowRadioCheck(false)
                    break
                  case 2:
                    setStage(stage - 1)
                    setNextBtnEnabled(false)
                    setShowRadioCheck(true)
                    setResetPlaceholder(true)
                    break
                  default:
                    return null
                }
              }}
              prevName={parse(t("Previous"))}
              btnLinkPrev={
                stage == 0 ? "/logisticgrowth/section1" : "#myHeader"
              }
              disabled={nextBtnEnabled == false}
              nextName={parse(t("Next"))}
              btnLinkNext={
                stage == 2 ? "/logisticgrowth/section3" : "#myHeader"
              }
              onClickNext={() => {
                setMsgType("")
                setMsgContent("")
                setShowMsg(false)
                setDisableChoices(false)
                setErrorCount(0)
                switch (stage) {
                  case 0:
                    setStage(1)
                    setNextBtnEnabled(false)
                    setShowRadioCheck(true)
                    break
                  case 1:
                    setStage(2)
                    setNextBtnEnabled(false)
                    setShowMsg(false)
                    setShowRadioCheck(false)
                    setResetPlaceholder(false)
                    break
                  default:
                    return null
                }
              }}
            />
          </Col>
        </Row>
      </div>
      <br />
    </Layout>
  )
}

export default LogGrowthSection2
